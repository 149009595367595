import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

const state = {
  departureData: null,
  tourData: null,
  sectionData: null,
  searchResult: null,
};

const getters = {
  getDepartureData: (state) => state.departureData,
  getTourData: (state) => state.tourData,
  getSectionData: (state) => state.sectionData,
  getSearchResult: (state) => state.searchResult,
};

const mutations = {
  updateTourData(state, payload) {
    state.tourData = payload;
  },
  updateSectionData(state, payload) {
    state.sectionData = payload;
  },
  updateSeachResult(state, payload) {
    state.searchResult = payload;
  },
  updateDepartureData(state, payload) {
    state.departureData = payload;
  },
};

const actions = {
  fetchDepartureData({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.gets(`/fuson-param/tour/departure/detail?id=${id}`)
        .then((data) => {
          commit('updateDepartureData', data.data.returnData);
          resolve(data.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  configData({ commit }, param) {
    return new Promise((resolve, reject) => {
      ApiService.post("/fuson-param/tour/find/few/config",param)
        .then((data) => {
          resolve(data.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchTourData({ commit }, param) {
    return new Promise((resolve, reject) => {
      ApiService.post("/fuson-param/client/departure/query",param)
        .then((res) => {
          commit('updateTourData', res.data.returnData);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchTourDataBySection({ commit }, param) {
    return new Promise((resolve, reject) => {
      ApiService.post("/fuson-param/client/section/query",param)
        .then((res) => {
          commit('updateSectionData', res.data.returnData);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchSeachResult({ commit }, param) {
    return new Promise((resolve, reject) => {
      ApiService.post("/fuson-param/client/queryByKeyword",{ keyword: param })
        .then((res) => {
          commit('updateSeachResult', res.data.returnData);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

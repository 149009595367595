<template>
  <div>
    <b-button v-b-modal.modal-b2 class="notify-hidden" id="notify-hidden"
      >RegisterModal</b-button
    >
    <b-modal
      id="modal-b2"
      :hide-footer="true"
      centered
      ref="modal-signup-and-in"
      @hide="hideModal"
    >
      <div
        class="card shadow-none border-0 pb-5 mb-3 card-body px-lg-5 py-lg-3 modal-from-tour"
      >
        <div>
          <b-tabs content-class="mt-3 px-5" align="center" v-model="tabIndex">
            <b-tab :title="$t('register.register')" class="register-modal">
              <div v-if="active == 1">
                <form
                  role="form"
                  class="pt-4 modal-form modal-form-sign-up"
                  @submit.prevent="submitFormSendOTP"
                  v-if="tabIndex == 0"
                >
                  <b-form-group class="userId-group">
                    <b-input-group>
                      <b-form-input
                        :placeholder="$t('register.loginIdPlaceHolder')"
                        class="py-3 shadow-sm"
                        v-validate="{
                          required: true,
                          min: 4,
                          max: 20,
                        }"
                        name="loginId"
                        :state="validateState('loginId')"
                        v-model="form_register.userId"
                        aria-describedby="input-loginId-Invalid"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-loginId-Invalid">
                        <span class="md-guide">{{
                          $t('register.loginIdGuide')
                        }}</span>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="gender-group">
                    <b-input-group>
                      <label></label>
                      {{ $t('register.male') }}&nbsp;&nbsp;<b-form-checkbox
                        id="checkbox-gendar-male"
                        checked="checked"
                        v-model="form_register.gender"
                        value="true"
                        inline
                      >
                      </b-form-checkbox>
                      {{ $t('register.female') }}&nbsp;&nbsp;<b-form-checkbox
                        v-model="form_register.gender"
                        value="false"
                        inline
                      ></b-form-checkbox>
                    </b-input-group>
                    <span class="error"></span>
                  </b-form-group>
                  <b-form-group class="fullName-group">
                    <b-input-group>
                      <b-form-input
                        :placeholder="$t('register.fullNamePlaceHolder')"
                        class="py-3 shadow-sm"
                        v-validate="{
                          required: true,
                        }"
                        name="fullName"
                        :state="validateState('fullName')"
                        aria-describedby="input-fullName-Invalid"
                        v-model="form_register.fullName"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-fullName-Invalid">
                        <span class="error">{{ $t('register.required') }}</span>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="nickName-group">
                    <b-input-group>
                      <b-form-input
                        placeholder="Enter your nickname"
                        class="py-3 shadow-sm"
                        v-model="form_register.nickName"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="phoneNumber-group">
                    <b-input-group>
                      <b-form-select
                        v-model="selected_phone_prefix"
                        :options="options_phone"
                        class="otp-btn phone_prefix"
                      ></b-form-select>
                      <b-form-input
                        :placeholder="$t('register.Enter_mobile_phone_number')"
                        v-model="form_register.phoneNumber"
                        v-validate="{
                          required: true,
                          numeric,
                          min: 8,
                          max: 11,
                        }"
                        name="phoneNumber"
                        :state="validateState('phoneNumber')"
                        aria-describedby="input-phoneNumber-Invalid"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-phoneNumber-Invalid">
                        <span class="error">{{
                          $t('register.errorPhoneNumber')
                        }}</span>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="Email-group">
                    <b-input-group>
                      <b-form-input
                        :placeholder="$t('register.emailPlaceHolder')"
                        class="py-3 shadow-sm"
                        v-validate="{
                          required: true,
                          email,
                        }"
                        name="email"
                        :state="validateState('email')"
                        aria-describedby="input-email-Invalid"
                        v-model="form_register.email"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-email-Invalid">
                        <span class="error">{{
                          $t('register.errorEmail')
                        }}</span></b-form-invalid-feedback
                      >
                    </b-input-group>
                    <!-- <span class="error"></span>
                    <span class="md-guide">{{
                      $t('register.emailGuide')
                    }}</span> -->
                  </b-form-group>
                  <b-form-group class="password-group">
                    <b-input-group>
                      <b-form-input
                        :placeholder="$t('register.passwordPlaceHolder')"
                        class="py-3 shadow-sm"
                        v-model="form_register.password_signup"
                        :state="validateState('password')"
                        aria-describedby="input-password-Invalid"
                        v-validate="{
                          required: true,
                          numeric,
                          min: 6,
                          max: 16,
                        }"
                        name="password"
                        ref="password"
                        type="password"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-password-Invalid">{{
                        $t('register.passwordGuide')
                      }}</b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="password-confirm-group">
                    <b-input-group>
                      <b-form-input
                        :placeholder="$t('register.confirmPasswordPlaceHolder')"
                        class="py-3 shadow-sm mb-3"
                        type="password"
                        name="confirmPassword"
                        v-validate="'required|confirmed:password'"
                        :state="validateState('confirmPassword')"
                        aria-describedby="input-password-confirm-Invalid"
                        data-vv-as="password"
                        v-model="form_register.passwordConfirm"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="input-password-confirm-Invalid"
                        >{{
                          $t('Register_and_Login.required_field_password')
                        }}.</b-form-invalid-feedback
                      >
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="join-member-group">
                    <b-input-group>
                      <label>{{ $t('register.joinMember') }}：</label>
                      {{ $t('register.yes') }}&nbsp;&nbsp;<b-form-checkbox
                        id="checkbox-not-member"
                        checked="checked"
                        v-model="form_register.member"
                        value="true"
                        inline
                      >
                      </b-form-checkbox>
                      {{ $t('register.no') }}&nbsp;&nbsp;<b-form-checkbox
                        v-model="form_register.member"
                        value="false"
                        inline
                      ></b-form-checkbox>
                    </b-input-group>
                    <span class="error"></span>
                  </b-form-group>
                  <div class="text-center">
                    <b-button
                      type="submit"
                      variant="second"
                      v-if="!busy"
                      class="my-4 btn-block btn-login"
                      >{{ $t('register.Register') }}</b-button
                    >
                    <b-button
                      class="my-4 btn-block btn-login"
                      v-else
                      variant="warning"
                      disabled
                    >
                      <b-spinner small type="grow"></b-spinner>
                      {{ $t('Register_and_Login.loading') }}...
                    </b-button>
                  </div>
                  <b-form-group class="login-text">
                    {{ $t('register.userServiceAgreement') }} <br />
                    {{ $t('register.Fengshun_Group_User_Service_Agreement') }}
                  </b-form-group>
                  <b-form-group class="float-right sign-up-link">
                    <!-- <span> 已有賬號？&nbsp; </span> <a href="#!"> 立即登錄 </a> -->
                  </b-form-group>
                </form>
              </div>
              <div v-else>
                <form
                  role="form"
                  class="pt-4 modal-form modal-form-sign-up"
                  @submit.prevent="submitFormVerifyOTP"
                >
                  <div>
                    <b-form-group class="otpCode-group">
                      <b-input-group>
                        <b-form-input
                          placeholder="Enter code OTP"
                          class="py-3 shadow-sm"
                          name="otpCode"
                          v-model="form_register.otpCode"
                          :state="validateState('otpCode')"
                          v-validate="{
                            required: true,
                            numeric,
                            min: 6,
                            max: 6,
                          }"
                          aria-describedby="input-2-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-2-live-feedback"
                          >{{
                            $t('Register_and_Login.required_field6')
                          }}.</b-form-invalid-feedback
                        >
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="text-center">
                    <b-button
                      type="submit"
                      v-if="!busy"
                      variant="second"
                      class="my-4 btn-block btn-login"
                      >{{ $t('Register_and_Login.loading') }}</b-button
                    >
                    <b-button
                      class="my-4 btn-block btn-login"
                      v-else
                      variant="warning"
                      disabled
                    >
                      <b-spinner small type="grow"></b-spinner>
                      {{ $t('Register_and_Login.loading') }}...
                    </b-button>
                  </div>
                </form>
              </div>
            </b-tab>
            <b-tab :title="$t('login.login')" class="login-modal">
              <!-- form sign-up-link -->
              <form
                role="form"
                class="pt-4 modal-form"
                @submit.prevent="submitFormLogin"
                v-if="tabIndex == 1"
              >
                <b-form-group>
                  <b-input-group class="form-group">
                    <b-form-input
                      :placeholder="$t('login.Enter_your_account')"
                      class="form__input py-3 shadow-sm"
                      v-model="form_login.userId"
                      v-validate="
                        'required|checkUserIDAndEmail' + checkUserIdOrEmail
                      "
                      name="username_login"
                      :state="validateState('username_login')"
                      aria-describedby="input-username-login-Invalid"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!checkEmail"
                      id="input-username-login-Invalid"
                    >
                      <span class="md-guide">{{
                        $t('register.loginIdGuide')
                      }}</span>
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-else
                      id="input-username-login-Invalid"
                    >
                      <span class="md-guide">{{
                        $t('register.errorEmail')
                      }}</span>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="form-group">
                  <b-input-group>
                    <b-form-input
                      :placeholder="$t('login.Enter_your_password')"
                      class="form__input py-3 shadow-sm"
                      type="password"
                      name="password_login"
                      :state="validateState('password_login')"
                      aria-describedby="input-password-login-Invalid"
                      v-validate="{
                        required: true,
                        numeric,
                        min: 6,
                        max: 16,
                      }"
                      v-model="form_login.password"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="input-password-login-Invalid"
                      >{{
                        $t('register.passwordGuide')
                      }}</b-form-invalid-feedback
                    >
                  </b-input-group>
                </b-form-group>
                <!-- <div class="error" v-if="!$v.password_login.required">Name is required</div> -->
                <!-- <div class="error" v-if="!$v.password_login.minLength">Name must have at least {{$v.password_login.$params.minLength.min}} letters.</div> -->
                <b-form-group class="float-right forgot-link">
                  <a
                    href="javascript:void(0);"
                    v-if="otpSw == 'Y'"
                    v-b-modal="'modal-password'"
                  >
                    {{ $t('login.Forgot_password') }}
                  </a>

                  <a href="javascript:void(0);" v-else @click="checkOtpSw()">
                    {{ $t('login.Forgot_password') }}
                  </a>
                </b-form-group>
                <div class="text-center button-login-group">
                  <b-button
                    type="submit"
                    variant="second"
                    class="my-4 btn-block btn-login"
                    v-if="!busy"
                    >{{ $t('login.login') }}</b-button
                  >
                  <b-button
                    class="my-4 btn-block btn-login"
                    v-else
                    variant="warning"
                    disabled
                  >
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t('Register_and_Login.loading') }}...
                  </b-button>
                </div>

                <b-form-group class="login-text">
                  <span> {{ $t('login.Successful_login_or_register') }} </span>
                  <br />
                  {{ $t('login.Fengshun_Group_User_Service_Agreement') }}
                </b-form-group>
              </form>

              <div></div>
            </b-tab>
          </b-tabs>
          <b-modal ref="modal-notify" id="modal-notify" :hide-footer="true">
            <p class="my-4 text-center" v-html="notify">{{ notify }}</p>
          </b-modal>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import enums from '@/store/enums';
import { enCryptDataLogin } from '@/utils/encrypt';
const phone_prefix = enums.phone_prefix;
export default {
  components: {
    // 'font-awesome-icon': FontAwesomeIcon,
  },
  data() {
    return {
      tabIndex: 0,
      submitStatus: null,
      form_login: {
        userId: '',
        password: '',
      },
      form_register: {
        userId: '',
        email: '',
        password_signup: '',
        passwordConfirm: '',
        fullName: '',
        nickName: '',
        otpCode: '',
        phoneNumber: '',
        gender: true,
        member: true,
        status: true,
      },
      notify: '',
      options_phone: [
        { value: '+' + phone_prefix.hk, text: '+' + phone_prefix.hk },
        { value: '+' + phone_prefix.mc, text: '+' + phone_prefix.mc },
        { value: '+' + phone_prefix.cn, text: '+' + phone_prefix.cn },
      ],
      selected_phone_prefix: '+' + phone_prefix.hk,
      readonly: true,
      active: 1,
      otpSw: 'Y', //Y,--N
      lang: this.$language,
      busy: false,
      checkUserIdOrEmail: '',
      checkEmail: false,
    };
  },
  created() {
    this.$root.$refs.A = this;
    // this.$store
    //   .dispatch('headerModule/getAllSite')
    //   .then(() => {
    //     this.otpSw = this.$store.getters['headerModule/getSite'].optSW;
    //     console.log(this.otpSw, '88');
    //   })
    //   .catch((error) => {
    //     this.errored = true;
    //   })
    //   .finally(() => (this.loading = false));
    
    const site = JSON.parse(localStorage.getItem('site'));
    this.otpSw=site.optSw
    this.$validator.extend('checkUserIDAndEmail', {
      getMessage: (field) =>
        field + 'Login ID is free format with 4 to 20 character long',
      validate: (value) => {
        if (value.split('@').length == 1) {
          this.checkUserIdOrEmail = '|min:4|max:20';
          this.checkEmail = false;
        } else {
          this.checkUserIdOrEmail = '|email';
          this.checkEmail = true;
        }
        return true;
      },
    });
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    setTabIndex: function (value) {
      this.tabIndex = value;
    },
    showModal() {
      this.$refs['modal-notify'].show();
    },
    hideModal() {
      this.resetForm();
      this.active = 1;
      this.$refs['modal-signup-and-in'].hide();
    },

    checkOtpSw() {
      if (this.otpSw == 'N') {
        const telephone = this.$store.getters['headerModule/getTelephone'];
        this.$vToastify.error({
          body: 'Please contact phone number ' + telephone,
          defaultTitle: false,
        });
      }
    },
    //登录
    submitFormLogin() {
      // console.log(window.location.origin+'/customer');
      this.busy = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.busy = false;
          return;
        }
        const dataSubmit = {
          userId: enCryptDataLogin(this.form_login.userId),
          password: enCryptDataLogin(this.form_login.password),
        };
        this.$store
          .dispatch('authModule/login', dataSubmit)
          .then(() => {
            const baseUrl = window.location.origin + window.location.pathname;
            const before_url = sessionStorage.getItem('before_url');
            if (before_url == null) {
              setTimeout(function () {
                window.location.assign(baseUrl);
              }, 1200);
              return;
            }
            setTimeout(function () {
              window.location.assign(before_url);
            }, 1200);
            this.$vToastify.success({
              body: this.lang.login.Logged_in_successfully,
              defaultTitle: false,
            });
            this.hideModal();
            this.busy = false;
          })
          .catch((error) => {
            if (error.statusCode != 200) {
              this.$vToastify.error({
                body: error.returnMsg,
                defaultTitle: false,
              });
            } else {
              this.$vToastify.error({
                body: this.$t('Register_and_Login.account_locked'),
                defaultTitle: false,
              });
            }
            this.busy = false;
          });
      });
    },
    //注册   N
    submitFormRegister() {
      console.log('注册---11--');
      const member = this.form_register.member === 'true' ? true : false;
      const gender = this.form_register.gender === true ? 'M' : 'F';

      const dataSubmit = {
        userId: this.form_register.userId,
        email: this.form_register.email,
        userPhone: this.form_register.phoneNumber,
        phonePrefix: this.selected_phone_prefix,
        otpCode: this.form_register.otpCode,
        password: this.form_register.password_signup,
        confirmPassword: this.form_register.passwordConfirm,
        userName: this.form_register.fullName,
        nickName: this.form_register.nickName,
        gender: gender,
        becomeAMember: member == true ? 'Y' : 'N',
      };

      this.$store
        .dispatch('authModule/userSignUp', dataSubmit)
        .then((data) => {
          this.busy = false;
          if (data.data.status == 'success') {
            this.submitFormLoginAfterRegister();
          } else {
            this.$vToastify.error({
              body: this.lang.register.registration_failed,
              defaultTitle: false,
            });
            this.notify =
              "<span class ='text-danger'>" +
              this.lang.register.registration_failed +
              '</span>';
          }
        })
        .catch((error) => {
          this.busy = false;
          const message = error.returnMsg;
          this.$vToastify.error({
            body: message,
            defaultTitle: false,
          });
        });
    },
    //注册验证成功后，登录接口
    submitFormLoginAfterRegister() {
      const dataSubmit = {
        userId: enCryptDataLogin(this.form_register.userId),
        password: enCryptDataLogin(this.form_register.password_signup),
      };

      this.$store
        .dispatch('authModule/login', dataSubmit)
        .then(() => {
          this.$vToastify.success({
            body: this.lang.login.Register_and_login_successfully,
            defaultTitle: false,
          });
          this.notify =
            "<span class ='text-success'>" +
            this.lang.login.Register_and_login_successfully +
            '</span>';

          const baseUrl = window.location.origin + window.location.pathname;
          const before_url = sessionStorage.getItem('before_url');
          if (before_url == null) {
            setTimeout(function () {
              window.location.assign(baseUrl);
            }, 1200);
            return;
          }
          setTimeout(function () {
            window.location.assign(before_url);
          }, 1200);
          this.hideModal();
        })
        .catch((error) => {
          const message = error.returnMsg;
          this.$vToastify.error({
            body: message,
            defaultTitle: false,
          });
        });
    },
    //填入验证码
    submitFormVerifyOTP() {
      this.busy = true;
      const member = this.form_register.member === 'true' ? true : false;
      const gender = this.form_register.gender === true ? 'M' : 'F';

      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.busy = false;
          return;
        }
        if (this.otpSw == 'Y') {
          this.$store
            .dispatch('authModule/signUpVerifyOtp', {
              userId: this.form_register.userId,
              email: this.form_register.email,
              userPhone: this.form_register.phoneNumber,
              phonePrefix: this.selected_phone_prefix,
              otpCode: this.form_register.otpCode,
              password: this.form_register.password_signup,
              confirmPassword: this.form_register.passwordConfirm,
              userName: this.form_register.fullName,
              nickName: this.form_register.nickName,
              gender: gender,
              becomeAMember: member == true ? 'Y' : 'N',
            })
            .then(() => {
              this.busy = false;
              this.submitFormLoginAfterRegister();
            })
            .catch((error) => {
              this.busy = false;
              const message = error.returnMsg;
              this.$vToastify.error({
                body: message,
                defaultTitle: false,
              });
            });
        } else {
          this.submitFormRegister();
        }
      });
    },

    submitFormSendOTP() {
      this.busy = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.busy = false;
          return;
        }
        if (this.otpSw == 'Y') {
          this.$store
            .dispatch('authModule/signUpSendOtp', {
              userId: this.form_register.userId,
              email: this.form_register.email,
              userPhone: this.form_register.phoneNumber,
              phonePrefix: this.selected_phone_prefix,
            })
            .then((data) => {
              this.busy = false;
              if (data.returnCode == '000000') {
                this.active = 2;
              } else {
                this.$vToastify.error({
                  body: data.returnMsg,
                  defaultTitle: false,
                });
              }
            })
            .catch((error) => {
              this.busy = false;
              const message = error.returnMsg;
              this.$vToastify.error({
                body: message,
                defaultTitle: false,
              });
            });
        } else {
          this.submitFormRegister();
        }
      });
    },
    resetForm() {
      this.form_register = {
        userId: '',
        email: '',
        password_signup: '',
        passwordConfirm: '',
        fullName: '',
        nickName: '',
        otpCode: '',
        phoneNumber: '',
        gender: true,
        member: true,
      };
      this.form_login = {
        userId: '',
        password: '',
      };

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
  },
};
</script>
<style type="text/css">
.modal-dialog {
  max-width: 680px;
}
.otp-btn {
  cursor: pointer;
}
.redhint {
  width: 20px;
}
.modal-backdrop {
  background: transparent;
}
.register-erorr {
  color: red !important;
  line-height: 3;
}
#modal-b2 .modal-content {
  background: linear-gradient(138deg, #e0f0fb 0%, #fcfcfc 57%, #fefdfc 100%);
  border-radius: 0;
  border: unset;
}
.modal-body {
  padding-top: 0;
}
.btn-login {
  background: #ffb423;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #ffb423;
  padding: 0.5rem 1.3rem;
  font-size: 1.5rem;
}

.modal-from-tour .nav-tabs {
  border: none;
}
.modal-from-tour
  .nav-tabs
  .nav-item
  .nav-link
  .nav-tabs
  .nav-item
  .nav-link.active {
  color: #191717 !important;
  border-top: unset !important;
  border: unset !important;
}
.modal-from-tour .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #999999;
}
.modal-from-tour .nav-tabs .nav-item .nav-link {
  font-size: 1.5rem;
  color: #999999;
  border: unset;
  position: relative;
  background: unset;
}
.modal-from-tour .nav-tabs .nav-link:hover,
.modal-from-tour .nav-tabs .nav-link:focus {
  border: unset !important;
}
.modal-from-tour .nav-tabs .nav-item .nav-link.active:hover {
  color: #191717;
  border: unset;
}
.modal-from-tour .nav-tabs .nav-item .nav-link.active {
  margin-top: 2px;
  color: #191717;
  border-top: unset;
}
.modal-from-tour .nav-tabs .nav-item:first-child a:after {
  content: '';
  border-right: 2px solid #cccccc;
  position: absolute;
  height: 50%;
  right: 0;
  top: 25%;
}
.modal-form input {
  border-radius: unset;
  border: 1px solid #fff;
}
.card-body {
  padding-top: 0 !important;
  background: unset;
}
.modal-form input,
.otp-btn {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16) !important;
}
.modal-header {
  border-bottom: unset;
}
.forgot-link a,
.sign-up-link {
  color: #999999;
}
.forgot-link a:hover {
  color: #000;
}
.sign-up-link a {
  color: #1997f8;
}
.login-text {
  font-size: 0.9rem;
  color: #999999;
}
.login-text span {
  color: #a5a5a5;
}
.input-group-text.otp-btn {
  background: #ffb423;
  color: #fff;
  border-radius: unset;
}
.modal-form-sign-up span.md-guide {
  color: #a5a5a5;
  padding: 0 0.6rem;
  line-height: 3;
}
.join-member-group label {
  margin-right: 1rem;
}
.join-member-group .custom-control-inline {
  margin-right: 0;
}
.join-member-group {
  color: #333333;
}
.join-member-group input {
  border-radius: 0;
}
.notify-hidden {
  display: none;
}
.phone_prefix {
  border-radius: unset;
}
.custom-select.phone_prefix {
  height: calc(1.56em + 1.06rem + 1px);
  border: 1px solid #fff;
  flex: unset;
  width: 22%;
}
.input-group-text.otp-btn:hover {
  color: #fff;
  background-color: #f4772e;
  border-color: #f4772e;
  box-shadow: 0 0.22rem 0.525rem rgb(244 119 46 / 40%),
    0 0.0625rem 0.385rem rgb(244 119 46 / 54%);
}
</style>

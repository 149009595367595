import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

const state = {
  bookedSeat: null,
  orderNumber: null,
  orders: null,
};

const getters = {
  getBookedSeat: (state) => state.bookedSeat,
  getOrderNumber: (state) => state.orderNumber,
  getOrders: (state) => state.orders,
};

const mutations = {
  updateBookedSeat(state, payload) {
    state.bookedSeat = payload;
  },
  updateOrderNumber(state, payload) {
    state.orderNumber = payload;
  },
  updateOrders(state, payload) {
    state.orders = payload;
  },
  clearData(state) {
    state.getBookedSeat = null;
    state.getOrderNumber = null;
  },
};

const actions = {
  getOrdersResponse({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/order/getUserOrders',params)
        .then(( data ) => {
          commit('updateOrders', data.data.returnData);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  submitPaymentStore({ commit }, bookingData) {
    return new Promise((resolve, reject) => {
      ApiService.post('/orders', bookingData)
        .then((res) => {
          commit('clearData');
          sessionStorage.removeItem('booking_deposit');
          sessionStorage.removeItem('payment_status');
          sessionStorage.removeItem('booking_total');
          sessionStorage.removeItem('dateOfTourSeleted');
          sessionStorage.removeItem('info_booking');
          sessionStorage.removeItem('booking_balance');
          sessionStorage.removeItem('timeline');
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  fetchBookedSeat({ commit }, datapost) {
    return new Promise((resolve, reject) => {
      ApiService.post('/orders/getBookedCount', datapost)
        .then((res) => {
          commit('updateBookedSeat', res.data.data.bookedCount);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  saveOrder({ commit }, bookingData) {
    return new Promise((resolve, reject) => {
      ApiService.post('/orders', bookingData)
        .then((res) => {
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  saveDefaultOrder({ commit }, bookingData) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/order/createAppOrderOffLine', bookingData)
        .then((res) => {
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  saveDefaultOrderOnline({ commit }, bookingData) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/order/createOnline', bookingData)
        .then((res) => {
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  editInfotOrder({ commit }, dataOrder) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/orders/${dataOrder.id}`, dataOrder)
        .then((res) => {
          resolve(res.data);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  updateOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.put('/orders', data)
        .then((res) => {
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  //todo: sprint4的功能
  cancelOrderById({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.patch('/orders', data.id, { status: data.status })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  getOrderById({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/order/findByOrderId',{ orderId:data.id})
        .then((res) => {
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  getOrderLastHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-order/order/findLastHistoryInfo',{})
        .then((res) => {
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  getPaymentsByOrderId({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/fuson-order/order/getPaymentsByOrderId?orderId=${data.id}`)
        .then((res) => {
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  getInvoiceInfoById({commit},paymentId){
    return  ApiService.get("/fuson-order/order/api/invoices?paymentId="+paymentId)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

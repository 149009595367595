import ApiService from "../service/ApiService";
function parseLabels(labels) {
  let labelMap = new Map();
  labels.map((label) => {
    if (labelMap.has(label.labelKey)) {
      labelMap.get(label.labelKey).push(label);
    } else {
      labelMap.set(label.labelKey, [label]);
    }
  });
  let keys = labelMap.keys();
  let key = keys.next();
  let labelList = [];
  while (!key.done) {
    let label1 = labelMap.get(key.value);
    const t = label1.reduce(
      (total, cur, index) => {
        let prefix = cur.languageType;
        total[prefix + 'Title'] = cur.title;
        cur.buttons &&
          total.labelButtons.push(
            ...cur.buttons.map((button) => {
              return {
                [prefix + 'Title']: button.label,
                url: button.url,
              };
            }),
          );
        return total;
      },
      { labelButtons: [] },
    );
    labelList.push(t);
    key = keys.next();
  }
  return labelList;
}

async function appLoading() {
  // const res=await ApiService.post('/fuson-param/client/get/base/config')
  try {
    console.log(234234324);
    const res = await ApiService.post('/fuson-param/client/get/base/config');
    const site = res.data.returnData;
    let data = {};
    data.phone = { phoneNumber: site.header.phone };
    data.menus = site.menus;
    data.areaSetting = site.areaSetting;
    data.banners = site.banners;
    data.logo = { link: site.header.logo };
    data.footer = {
      icons: site.footer.others.icons,
      copyright: site.footer.others.copyright,
      phoneNumber:
        site.footer.others.contactInfos[0] &&
        site.footer.others.contactInfos[0].hotLine,
      label1: {},
      label2: {},
      label3: {},
    };

    site.footer.others.contactInfos.forEach((ele) => {
      if (ele.languageType == 'en') {
        data.footer.enInformation = ele.contactDescription;
        data.footer.enTitle = ele.contactLabel;
      } else if (ele.languageType == 'cn') {
        data.footer.cnInformation = ele.contactDescription;
        data.footer.cnTitle = ele.contactLabel;
      } else {
        data.footer.hkInformation = ele.contactDescription;
        data.footer.hkTitle = ele.contactLabel;
      }
    });

    site.footer.labels.forEach((item, index) => {
      if (item.labelKey == 'Label1') {
        if (item.languageType == 'en') {
          data.footer.label1.enTitle = item.title;
          data.footer.label1.enBtns = item.buttons;
        } else if (item.languageType == 'cn') {
          data.footer.label1.cnTitle = item.title;
          data.footer.label1.cnBtns = item.buttons;
        } else {
          data.footer.label1.hkTitle = item.title;
          data.footer.label1.hkBtns = item.buttons;
        }
      } else if (item.labelKey == 'Label2') {
        if (item.languageType == 'en') {
          data.footer.label2.enTitle = item.title;
          data.footer.label2.enBtns = item.buttons;
        } else if (item.languageType == 'cn') {
          data.footer.label2.cnTitle = item.title;
          data.footer.label2.cnBtns = item.buttons;
        } else {
          data.footer.label2.hkTitle = item.title;
          data.footer.label2.hkBtns = item.buttons;
        }
      } else {
        if (item.languageType == 'en') {
          data.footer.label3.enTitle = item.title;
          data.footer.label3.enBtns = item.buttons;
        } else if (item.languageType == 'cn') {
          data.footer.label3.cnTitle = item.title;
          data.footer.label3.cnBtns = item.buttons;
        } else {
          data.footer.label3.hkTitle = item.title;
          data.footer.label3.hkBtns = item.buttons;
        }
      }
    });
    // console.log(999,site.footer.labels)
    let labels = parseLabels(site.footer.labels);
    data.footer.label1 = labels[0];
    data.footer.label2 = labels[1];
    data.footer.label3 = labels[2];
    data.optSw = site.optSW;
    data.languageSetting = site.languageSetting;
    const sitedata = data;
    localStorage.setItem('site', JSON.stringify(sitedata));
  } catch (e) {
    console.log('System Error',e);
    return Promise.reject(false)
  }
}
export default appLoading
<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const default_layout = 'default';
import ApiService from './service/ApiService';


export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
  },
};
</script>

<style lang="scss">
@import 'assets/bamburgh.scss';
</style>

import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

const state = {
  parameters: null,
};

const getters = {
  getParameters: (state) => state.parameters,
  getMessageConfig: (state) => state.messageConfig,
};

const mutations = {
  updateParameter(state, payload) {
    state.parameters = payload;
  },
  setConfig(state, payload) {
    state.messageConfig = payload;
  },
};

const actions = {
  getParameters({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/parameters/61d7e741675623d258015187')
        .then((res) => {
          commit('updateParameter', res.data.data);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  getMessageConfig({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/fuson-param/client/parameters')
        .then(({ data }) => {
          commit('setConfig', data.returnData);
          resolve(data.returnData)
        })
        .catch((data) => {
          reject(data);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <b-col md="6" offset-md="3">
    <div
      class="input-group md-form form-sm form-2 pl-0 search-md"
      v-if="loading"
    >
      <input
        class="form-control my-0 py-1 amber-border"
        v-model="key_search"
        type="text"
        :placeholder="$t('SearchBar')"
        aria-label="搜目的地/攻略/旅行優惠"
      />
      <div class="input-group-append">
        <span
          class="input-group-text amber lighten-3 icon-search"
          id="search-button"
          v-on:click="submitFormSearch"
        >
          <img src="@/assets/img/icon/icon-search.png" alt="Image" />
        </span>
      </div>
    </div>
  </b-col>
</template>
<script type="text/javascript">
var baseUrl = window.location.origin;
import router from "@/router.js"
export default {
  name: 'SearchBar',
  components: {},
  data() {
    return {
      info: null,
      loading: true,
      errored: false,
      infolength: null,
      slug: null,
      key_search: '',
    };
  },
  mounted() {
    this.loading = false;
    setTimeout(() => {
      this.loading = true;
    }, 1500);
  },
  methods: {
    submitFormSearch() {
      // window.location.assign(baseUrl + '/#/search/' + this.key_search);
      router.push('/search/' + this.key_search)
      // location.reload();
      return;
    },
  },
};
</script>
<style type="text/css">
.icon-search img {
  width: 2rem;
}
.icon-search {
  background-color: #f6a604;
  border: 1px solid #f6a604;
  padding: 0.33rem 0.8rem;
  cursor: pointer;
  box-shadow: 0 0.22rem 0.525rem rgb(248 249 255 / 40%),
    0 0.0625rem 0.385rem rgb(248 249 255 / 54%);
}
.icon-search:hover {
  background-color: #090d9c;
  border: 1px solid #090d9c;
}
.search-md input,
.search-md .icon-search {
  border-radius: 0;
}
.search-md {
  position: absolute;
  left: 0;
  top: -1.5rem;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.search-md input {
  height: calc(1.8em + 1.06rem + 2px);
}
</style>

import { _ } from 'core-js';
import jwtDecode from 'jwt-decode';
import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

export const SET_AUTH = 'setAuth';
export const CLEAR_AUTH = 'clearAuth';

const state = {
  username: null,
  userType: null,
  isAuthenticated: false,
};

const getters = {
  getAuthentication: (state) => state.isAuthenticated,
  token: (state) => state.token,
  getUserName: (state) => state.username,
  getUserType: (state) => state.userType,
};

const mutations = {
  updateAuthentication(state) {
    state.username = localStorage.getItem('username');
    state.userType = localStorage.getItem('userType');
    state.isAuthenticated = null !== localStorage.getItem('exp');
  },
  clearAuth(state) {
    state.username = null;
    state.userType = null;
    state.isAuthenticated = false;
  },
};

const actions = {
  //登录
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-auth/user/customer/login', credentials)
        .then((res) => {
          if (res.data.returnCode == '000000') {
            const data = res.data.returnData;
            const token = data.token;
            const payload = jwtDecode(token);
            const userName = data.nickName === null || data.nickName === '' ? data.fullName : data.nickName;
            localStorage.setItem('username', userName);
            localStorage.setItem('token', token);
            localStorage.setItem('userType', 'USER');//目前只有USER :from 华南
            localStorage.setItem('exp', payload.exp);
            commit('updateAuthentication');
            localStorage.setItem('fmember', data.member);
            localStorage.setItem('userId', data.userId);
            resolve();
          } else {
            reject(res.data);
          }
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  forgotPassword({ commit }, userId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/users/forgot-password/${userId}`)
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  userSignUp({ commit }, data) { 
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-auth/user/customer/register/direct/login', data)
        .then((res) => {
          const data = res;
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  //注册接口
  signUpSendOtp({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-auth/user/customer/register', data)
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data);
        });
    });
  },
  //注册-验证码激活
  signUpVerifyOtp({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-auth/user/customer/active', data)
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data);
        });
    });
  },
  verifyCode({ commit }, userIdAndCode) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/users/forgot-password/${userIdAndCode.userId}`, {
        code: userIdAndCode.code,
      })
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  resetPassword({ commit }, userIdAndpwAndConfirmPw) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `/users/forgot-password`,
        userIdAndpwAndConfirmPw.userId,
        userIdAndpwAndConfirmPw,
      )
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  logout({ commit }) {
    return new Promise((reject) => {
      localStorage.removeItem('fmember');
      localStorage.removeItem('username');
      localStorage.removeItem('userType');
      localStorage.removeItem('userId');
      localStorage.removeItem('exp');
      commit(CLEAR_AUTH);
      window.location.assign(window.location.origin+window.location.pathname);
      return
      ApiService.get('/users/logout')
        .then(() => {
          setTimeout(function () {
            localStorage.removeItem('fmember');
            localStorage.removeItem('username');
            localStorage.removeItem('userType');
            localStorage.removeItem('userId');
            localStorage.removeItem('exp');
            commit(CLEAR_AUTH);
            window.location.assign(window.location.origin);
          }, 1000);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
  getUserByFillter({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-auth/user/specified/info', data)
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  keeplogin({ commit }) {
    commit('updateAuthentication');
  },
  clearAuthError({ commit }) {
    localStorage.removeItem('fmember');
    localStorage.removeItem('username');
    localStorage.removeItem('userType');
    localStorage.removeItem('userId');
    localStorage.removeItem('exp');
    commit(CLEAR_AUTH);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import router from '../router';
import store from '../store/store';

const configResponse = (response) => {
  if (response && response.status === 401) {
    store.dispatch('authModule/clearAuthError');
    Vue.$vToastify.error({
      body: (response.data && response.data.message) || 'Login again, Please',
      defaultTitle: false,
    });
    return router.push('/');
  }
};

export default configResponse;

export default {
  phone_prefix: {
    hk: '852',
    mc: '853',
    cn: '86',
  },
  stripe: {
    public_token:
      // 'pk_test_51KQiS8Hhk4AI0DSBEXJDyO0z1SUVyu7hcMOMlu5HJo2MU4kvgFr8coAjqNk0Vt60GqTs6RytuKxCG8iUjHCK2Red00p9P5BeBt',
      'pk_test_51PkHQsIutvh5FmwUTMvC6hwMp4MbGkOybjwofsWdjSvcLGJzamTJCWX7ZRXKaEJSmrzASQwZUF89Fuf5hNhH36Mf005ZpUyASG',
  },
  section: {
    section1: 1,
    section2: 2,
    section3: 3,
    section4: 4,
  },
  order_status: {
    DEPOSIT: -1,
    FULLPAID: 0,
    INCOMPLETE: 1,
    CANCEL: 2,
    CLOSE: 3,
  },
  link: {
    YES: 'Y',
    NO: 'N',
  },
  hidden_flag: {
    DISPLAY: 1,
    HIDE: 0,
  },
  otpMthd: {
    EMAIL: 'E',
    OTP: 'O',
  },
};

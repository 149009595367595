import Vue from "vue";
import VueI18n from "vue-i18n";
import langCn from "./cn.json";
import langEn from "./en.json";
import langHk from "./hk.json";

Vue.use(VueI18n);

const messages = {
  en: langEn,
  cn: langCn,
  hk: langHk,
};

const i18n = new VueI18n({
  locale: "en", // set locale
  messages,
  fallbackLocale: "en",
});

export default i18n;

import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

const state = {
  page: null,
};

const getters = {
  getPage: (state) => state.page,
};

const mutations = {
  updatePage(state, payload) {
    state.page = payload;
  },
};

const actions = {
  fetchPageByPageCode({ commit }, pageCode) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-param/client/page/detail/' + pageCode)
        .then(({data}) => {
          resolve(data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

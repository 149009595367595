import ApiService from '../../service/ApiService';

const state = {
  data: null,
};

const getters = {
  getFooterData: (state) => state.data,
};

const mutations = {
  updateFooterData(state, payload) {
    state.data = payload;
  },
};

const actions = {
  fetchFooterData({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/footer')
        .then((res) => {
          commit('updateFooterData', res.data.data);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
